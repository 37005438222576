import Login from './pages/login';
import Logout from './pages/logout';

import NotFound from './pages/notFound';

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import React from 'react';

import { useSelector } from "react-redux";

import {
  selectUsuarioSeleccionado,
} from "./redux/reducer/loginReducer";
import AlumnosTutor from './pages/alumnos/alumnosTutor';
import AlumnoForm from './pages/alumnos/alumnoForm/AlumnoForm';
// import AlumnoFormCreaEntrega from './pages/alumnos/alumnoFormCreaEntrega';
import PlantillasEntregas from './pages/alumnos/plantillasEntregas';
import PlantillasEntregasDetalle from './pages/alumnos/plantillasEntregasDetalle';
import MatrizEstados from './pages/alumnos/matrizEntregas';
import AsignacionMasiva from './pages/alumnos/asignacionMasiva';
import Coordinacion from './pages/coordinacion';
import Notificaciones from './pages/notificaciones';
import AlumnosRegistrados from './pages/alumnos/alumnosRegistrados';
import TutoriasIndividuales from './pages/tutoriasIndividuales';
import TutoriasIndividualesSinAlumno from './pages/tutoriasIndividualesSinAlumno';
import AdminDifusion from './pages/adminDifusion';
import CargaTutores from './pages/cargaTutores';
import TutoriasGrupales from './pages/tutoriasGrupales';
import TutoresTutoriasGrupales from './pages/tutoresTutoriasGrupales';
import MatrizSimulacros from './pages/alumnos/matrizSimulacros';
import Liquidaciones from './pages/liquidaciones';
import AlumnoConversacion from './pages/alumnos/alumnoConversacion/AlumnoConversacion';
import TutoriasGrupalesEnlaces from './pages/tutoriasGrupalesEnlaces';
import NotificacionesAlumnos from './pages/notificaciones-alumnos';
import BandejaDeEntrada from './pages/bandejaDeEntrada';
import ConversacionesApoyo from './pages/apoyoTutores';
import ExposicionesOrales from './pages/exposicionesOrales';
import Grupos from './pages/alumnos/grupos';
import GruposDetalle from './pages/alumnos/grupoDetalle';
import PlantillasPermisos from './pages/plantillasPermisos';
import PlantillasPermisosDetalle from './pages/plantillasPermisosDetalle';

/* --------------------------------- Private -------------------------------- */
function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

/* ---------------------------------- Home ---------------------------------- */
// const RouteHome = () => {
//   const usuarioSeleccionado = useSelector(state => {
//     return selectUsuarioSeleccionado(state);
//   });

//   console.log(usuarioSeleccionado)
//   return (
//     ( usuarioSeleccionado.perfil_id === "6" ?
//       <Route component={AlumnosRegistradosAndaluciaListado} path="/home"></Route>
//       :
//       <Route component={Home} path="/home"></Route>
//     )
//   );
// }


/* --------------------------------- Alumnos -------------------------------- */
const RouteAlumnosTutorList = () => {
  return (
    <>
      <Route component={AlumnosTutor} path="/alumnos_tutor/list"></Route>
    </>
  );
}
const RouteAlumnosTutor = () => {
  return (
    <>
      <Route component={AlumnosTutor} path="/alumnos_tutor"></Route>
    </>
  );
}
// const RoutePlantillasEntregas = () => {
//   return (
//     <>
//       <Route component={PlantillasEntregas} path="/plantillas_entregas"></Route>
//     </>
//   );
// }
const RoutePlantillasPermisos = () => {
  return (
    <>
      <Route component={PlantillasPermisos} path="/plantillas_permisos"></Route>
    </>
  );
}
const RoutePlantillasPermisosDetalle = () => {
  return (
    <>
      <Route component={PlantillasPermisosDetalle} path="/plantillas_permisos/detalle/:id"></Route>
    </>
  );
}

const RouteGrupos = () => {
  return (
    <>
      <Route component={Grupos} path="/grupos"></Route>
    </>
  );
}
const RoutePlantillasEntregasDetalle = () => {
  return (
    <>
      <Route component={PlantillasEntregasDetalle} path="/plantillas_entregas/detalle/:id"></Route>
    </>
  );
}
const RouteGruposDetalle = () => {
  return (
    <>
      <Route component={GruposDetalle} path="/grupos/detalle/:id"></Route>
    </>
  );
}
const RouteAsignacionMasiva = () => {
  return (
    <>
      <Route component={AsignacionMasiva} path="/plantillas_entregas/asignacion_masiva"></Route>
      <Route component={AsignacionMasiva} path="/asignacion_masiva"></Route>
    </>
  );
}
const RouteAlumnoDetalle = () => {
  return (
    <>
      <Route component={AlumnoForm} path="/alumnos_tutor/detalle/:id"></Route>
    </>
  );
}
const RouteAlumnoConversacion = () => {
  return (
    <>
      <Route component={AlumnoConversacion} path="/alumnos_tutor/conversacion/:alumno_id/:origen_tipo/:origen_id"></Route>
    </>
  );
}
// const RouteAlumnoDetalleCreaEntrega = () => {
//   return (
//     <>
//       <Route component={AlumnoFormCreaEntrega} path="/alumnos_tutor/detalle/:id/crea_entrega"></Route>
//     </>
//   );
// }
const RouteMatrizEstados = () => {
  return (
    <>
      <Route component={MatrizEstados} path="/matriz_estados"></Route>
    </>
  );
}
const RouteMatrizSimulacros = () => {
  return (
    <>
      <Route component={MatrizSimulacros} path="/matriz_simulacros"></Route>
    </>
  );
}
// const RouteAlumnosRegistrados = () => {
//   return (
//     <>
//       <Route component={AlumnosRegistrados} path="/alumnos_registrados"></Route>
//     </>
//   );
// }
const RouteCoordinacion = () => {
  return (
    <>
      <Route component={Coordinacion} path="/coordinacion"></Route>
    </>
  );
}
const RouteNotificaciones = () => {
  return (
    <>
      <Route component={Notificaciones} path="/notificaciones"></Route>
    </>
  );
}
const RouteNotificacionesAlumnos = () => {
  return (
    <>
      <Route component={NotificacionesAlumnos} path="/notificaciones-alumnos"></Route>
    </>
  );
}
const RouteTutoriasGrupales = () => {
  return (
    <>
      <Route component={TutoriasGrupales} path="/tutorias_grupales"></Route>
    </>
  );
}
const RouteTutoriasEnlacesGrupales = () => {
  return (
    <>
      <Route component={TutoriasGrupalesEnlaces} path="/tutorias_grupales_enlaces"></Route>
    </>
  );
}
const RouteTutoresTutoriasGrupales = () => {
  return (
    <>
      <Route component={TutoresTutoriasGrupales} path="/tutores_tutorias_grupales"></Route>
    </>
  );
}
const RouteTutoriasIndividuales = () => {
  return (
    <>
      <Route component={TutoriasIndividuales} path="/tutorias_individuales"></Route>
    </>
  );
}
const RouteTutoriasIndividualesSinAlumno = () => {
  return (
    <>
      <Route component={TutoriasIndividualesSinAlumno} path="/tutorias_individuales_sin_alumno"></Route>
    </>
  );
}
// const RouteAdminDifusion = () => {
//   return (
//     <>
//       <Route component={AdminDifusion} path="/admin_difusion"></Route>
//     </>
//   );
// }
const RouteCargaTutores = () => {
  return (
    <>
      <Route component={CargaTutores} path="/carga_tutores"></Route>
    </>
  );
}
const RouteLiquidaciones = () => {
  return (
    <>
      <Route component={Liquidaciones} path="/liquidaciones"></Route>
    </>
  );
}
const RouteBandejaDeEntrada = () => {
  return (
    <>
      <Route component={BandejaDeEntrada} path="/bandeja-de-entrada"></Route>
    </>
  );
}
const RouteApoyoTutores = () => {
  return (
    <>
      <Route component={ConversacionesApoyo} path="/apoyo-tutores"></Route>
    </>
  );
}
const RouteExposicionesOrales = () => {
  return (
    <>
      <Route component={ExposicionesOrales} path="/exposiciones-orales"></Route>
    </>
  );
}


export default function App(props) {
  
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  return (
    <BrowserRouter>
      <Switch>

        {/* ZONA PUBLICA */}
        <Route component={Login} path="/login"></Route>
        <Route component={Logout} path="/logout"></Route>
        <Route exact path="/">
          <div className="container">
            {
              usuarioSeleccionado ?
                <Redirect to={{ pathname: '/alumnos_tutor', state: { from: props.location } }} />
              :
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            {/* <Link className="btn btn-secondary btn-lg btn-block" to="/login">Login</Link> */}
            {/* <Link className="btn btn-secondary btn-lg btn-block" to="/home">Home</Link> */}
          </div>
        </Route>

        {/* ZONA PRIVADA */}
        {/* <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_tutor/asignacion_masiva' component={RouteAlumnosTutorAsignacionMasiva} /> */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/matriz_estados' component={RouteMatrizEstados} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/matriz_simulacros' component={RouteMatrizSimulacros} />

        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_tutor/list' component={RouteAlumnosTutorList} />
        {/* <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_tutor/detalle/:id/crea_entrega' component={RouteAlumnoDetalleCreaEntrega} /> */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_tutor/detalle/:id' component={RouteAlumnoDetalle} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_tutor/conversacion/:alumno_id/:origen_tipo/:origen_id' component={RouteAlumnoConversacion} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_tutor' component={RouteAlumnosTutor} />
        {/* <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_registrados' component={RouteAlumnosRegistrados} /> */}
        
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/plantillas_entregas/detalle/:id' component={RoutePlantillasEntregasDetalle} />
        {/* <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/plantillas_entregas' component={RoutePlantillasEntregas} /> */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/plantillas_permisos/detalle/:id' component={RoutePlantillasPermisosDetalle} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/plantillas_permisos' component={RoutePlantillasPermisos} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/grupos/detalle/:id' component={RouteGruposDetalle} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/grupos' component={RouteGrupos} />
        
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/asignacion_masiva' component={RouteAsignacionMasiva } />
        
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/coordinacion' component={RouteCoordinacion } />
        
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/notificaciones-alumnos' component={RouteNotificacionesAlumnos } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/notificaciones' component={RouteNotificaciones } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tutorias_grupales' component={RouteTutoriasGrupales } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tutorias_grupales_enlaces' component={RouteTutoriasEnlacesGrupales } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tutores_tutorias_grupales' component={RouteTutoresTutoriasGrupales } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tutorias_individuales' component={RouteTutoriasIndividuales } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tutorias_individuales_sin_alumno' component={RouteTutoriasIndividualesSinAlumno } />
        {/* <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/admin_difusion' component={RouteAdminDifusion } /> */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/carga_tutores' component={RouteCargaTutores } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/liquidaciones' component={RouteLiquidaciones } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/bandeja-de-entrada' component={RouteBandejaDeEntrada } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/apoyo-tutores' component={RouteApoyoTutores } />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/exposiciones-orales' component={RouteExposicionesOrales } />
        
        {/* PAGINA NO ENCONTRADA */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}