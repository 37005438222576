import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


// import EmptyComponent from '../../../../components/EmptyContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { actualizarCalendly, actualizarCalendlyTutoriasGrupales, delete_tutoria_grupal, getAlumnosCoordinador, getTutoriasGrupales, getTutoriasIndividuales, tutoriaGrupalInformarAlumnos, tutoriaGrupalInformarSolicitante } from '../../../services/alumnos';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
// import { Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { Box, Button, Chip, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import SyncIcon from '@material-ui/icons/Sync';
// import Swal from 'sweetalert2';
// import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// import PropTypes from 'prop-types';
// import TituloPagina from '../../../../components/Titulos/TituloPagina';
// import AddIcon from '@material-ui/icons/Add';
// import { getAlumnosTutor } from '../../../../services/alumnos';
// import { Stack } from '@mui/system';
// import Moment from 'react-moment';
// import { DataGridPro, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
import StatusTutor from '../../../components/StatusTutor';
import StatusEntregasAlumno from '../../../components/StatusEntregasAlumno';
import StatusTicketsAlumno from '../../../components/StatusTicketsAlumno';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { blue, green, red } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
import { Stack } from '@mui/material';
import { DialogTutoriaGrupalForm } from './DialogTutoriaGrupalForm';
// import moment from 'moment/moment';
// import { amber, blue, green, red, yellow } from '@material-ui/core/colors';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function TutoriasGrupalesContent() {
    const classes = useStyles();

    const [tutorias, setTutorias] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [openDialogoTutoriaGrupal, setOpenDialogoTutoriaGrupal] = useState(false)
    const [tutoriaGrupalSeleccionada, setTutoriaGrupalSeleccionada] = useState(false)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    // // const [selectionModel, setSelectionModel] = React.useState([]);
    // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando tutorias")

        const usuario_id = usuarioSeleccionado.id

        // actualizarCalendlyTutoriasGrupales({ usuario_id: usuario_id }).then((response) => {
          getTutoriasGrupales({ usuario_id: usuario_id }).then((response) => {
            setLoading(false)

            response && setTutorias(response.tutorias)
          });
        // });

    }, [])

    const handleEliminarTutoria = (fila) => {
      Swal.fire({
        title: '¿Realmente deseas eliminar la tutoría grupal?',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: `Cancelar`,
        confirmButtonColor: '#afcb0d',
        cancelButtonColor: '#a5a5a5',
        icon: 'warning',
        reverseButtons: true
      }).then((result) => {

        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            
          delete_tutoria_grupal({usuario_id: usuarioSeleccionado.id, tutoria_grupal_id: fila.id}).then( (response) => {
            getTutoriasGrupales({ usuario_id: usuarioSeleccionado.id }).then((response) => {
              setLoading(false)
    
              response && setTutorias(response.tutorias)
            });
          })

        } else if (result.isDenied) {
//         //   Swal.fire('Changes are not saved', '', 'info')
        }
      })


      
    }

    const handleInformarSolicitante = (fila) => {

      Swal.fire({
        title: '¿Realmente deseas informar?',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        cancelButtonText: `Cancelar`,
        confirmButtonColor: '#afcb0d',
        cancelButtonColor: '#a5a5a5',
        icon: 'warning',
        reverseButtons: true
      }).then((result) => {

        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          tutoriaGrupalInformarSolicitante({usuario_id: usuarioSeleccionado.id, tutoria_grupal_id: fila.id }).then( (response) => {
            Swal.fire("Solicitante informado")
          })

        } else if (result.isDenied) {
//         //   Swal.fire('Changes are not saved', '', 'info')
        }
      })

      
    }
    const handleInformarAlumnos = (fila) => {

      Swal.fire({
        title: '¿Realmente deseas informar?',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        cancelButtonText: `Cancelar`,
        confirmButtonColor: '#afcb0d',
        cancelButtonColor: '#a5a5a5',
        icon: 'warning',
        reverseButtons: true
      }).then((result) => {

        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          tutoriaGrupalInformarAlumnos({usuario_id: usuarioSeleccionado.id, tutoria_grupal_id: fila.id }).then( (response) => {
            Swal.fire("Alumnos informados")
          })

        } else if (result.isDenied) {
//         //   Swal.fire('Changes are not saved', '', 'info')
        }
      })

      
    }
    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { 
        field: 'mail', 
        headerName: 'Sala', 
        minWidth: 350, 
        renderCell: (celda) => {
            return <>{celda.row.mail }</>

        }
      },
      { 
        field: 'invitado', 
        headerName: 'Solicitante', 
        minWidth: 350, 
        renderCell: (celda) => {
            return <>{celda.row.invitado }</>

        }
      },
      { 
        field: 'grupo_descripcion', 
        headerName: 'Grupo', 
        minWidth: 350, 
        renderCell: (celda) => {
            return <>{celda.row.grupo_descripcion }</>

        }
      },

      { field: 'start_time', headerName: 'Inicio',minWidth: 170},
      { 
        field: 'estado', 
        headerName: 'Estado',
        minWidth: 130, 
        renderCell: (celda) => {
          return (
            <>
              { celda.row.estado === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
              { celda.row.estado === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
              { celda.row.estado === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
              { celda.row.estado === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
            </>
          )
        }
      },
      { 
        field: 'zoom_link', 
        headerName: 'Enlace Zoom',
        minWidth: 400, 
        flex: 1,
        renderCell: (cell) => {
          return <a href={cell.value} target='_blank' rel="noreferrer">{cell.value}</a>
        }
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            // icon={<EditIcon />}
            label="Eliminar tutoría"
            onClick={ () => { 
                handleEliminarTutoria(params.row) 
            }}
            // component={Link}
            // to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
          <GridActionsCellItem
            // icon={<EditIcon />}
            label="Informar a solicitante"
            onClick={ () => { 
                handleInformarSolicitante(params.row) 
            }}
            // component={Link}
            // to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
          <GridActionsCellItem
            // icon={<EditIcon />}
            label="Informar alumnos"
            onClick={ () => { 
                handleInformarAlumnos(params.row) 
            }}
            // component={Link}
            // to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
        ],
      }
    ]

    const handleOpenDialogTutoriaGrupal = (item) => {

        item && setTutoriaGrupalSeleccionada(item.row)
        !item && setTutoriaGrupalSeleccionada(undefined)
        setOpenDialogoTutoriaGrupal(true)
    }
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        return (
            tutorias ?
                <>
                    
                    <Stack direction={'row'} spacing={2}>
        
                      <Box mt={0} mb={0} mx={2}>
                        <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                            Tutorías grupales
                        </Typography>
                      </Box>            
                      <Button variant="outlined" color="primary" onClick={() => handleOpenDialogTutoriaGrupal()}>Nueva tutoría grupal</Button>
                    </Stack>
                    
                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridPro
                              rows={tutorias} 
                              columns={columnas} 
                              rowHeight={30}
                              onRowDoubleClick={ ( evento ) => handleOpenDialogTutoriaGrupal(evento) }
                              // componentsProps={{
                              //     toolbar: {
                              //       value: searchText,
                              //       onChange: (event) => requestSearch(event.target.value),
                              //       clearSearch: () => requestSearch(''),
                              //     },
                              // }}
                          />
                      </div>
                    </Box>

                    <DialogTutoriaGrupalForm
                      tutoriaGrupal={tutoriaGrupalSeleccionada}
                      openModal={openDialogoTutoriaGrupal}
                      handleOpenModal={setOpenDialogoTutoriaGrupal}
                      setTutorias={setTutorias}
                    />

                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
