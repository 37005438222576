import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Grid, ListSubheader } from '@material-ui/core'



import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PeopleIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/Notifications';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InboxIcon from '@mui/icons-material/Inbox';

import avatar from '../../assets/img/avatar.png';

import {
    selectUsuarioSeleccionado,
} from "../../redux/reducer/loginReducer";

import {
    Link,
} from 'react-router-dom';

import { PRIMARY_COLOR } from '../../constants/colores'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appbar: {
        backgroundColor: PRIMARY_COLOR,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        lineHeight: '50px'
    },
    avatar: {
        width: 250,
    },
    list: {
        width: 250,
        marginTop: 10
    },
    fullList: {
        width: 'auto',
    },
}));


const NavbarTop = (props) => {
    const { title = null, showBack } = props

    const classes = useStyles();

    // --------------------------------------------------------------
    // handleAbrirSidebar
    //
    // Permite abrir y cerrar la sidebar
    // --------------------------------------------------------------
    // const dispatch = useDispatch();

    // --------------------------------------------------------------
    // Usuario seleccionado
    // --------------------------------------------------------------
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    // const preventDefault = (event) => event.preventDefault();



    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const anchor = 'left';

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            
            
            {/* perfil_id = 8 => tutor */}
            {(usuarioSeleccionado.perfil_id === "8") && (
                <>
                    <ListItem button component={Link} to="/bandeja-de-entrada" >
                        <ListItemIcon><InboxIcon /></ListItemIcon>
                        <ListItemText primary="Bandeja de entrada" />
                    </ListItem>
                    <ListSubheader>ALUMNOS</ListSubheader> 
                    <ListItem button component={Link} to="/alumnos_tutor/list" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Listado de Alumnos" />
                    </ListItem>
                    <ListItem button component={Link} to="/apoyo-tutores" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Apoyo a tutores" />
                    </ListItem>
                    <ListItem button component={Link} to="/matriz_estados" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Matriz entregas" />
                    </ListItem>
                    <ListItem button component={Link} to="/matriz_simulacros" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Matriz simulacros" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutorias_individuales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías individuales" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutores_tutorias_grupales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías grupales" />
                    </ListItem>
                    <ListItem button component={Link} to="/exposiciones-orales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Exposiciones orales" />
                    </ListItem>
                    <ListItem button component={Link} to="/notificaciones_tutor_alumnos" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones alumnos" />
                    </ListItem>
                    <Divider />
                    <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                    <ListItem button component={Link} to="/liquidaciones" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Liquidaciones" />
                    </ListItem>
                    {/* <ListItem button component={Link} to="/notificaciones" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones" />
                    </ListItem> */}
                    <ListItem button component={Link} to="/notificaciones-alumnos" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones alumnos" />
                    </ListItem>
                </>
                
            )}

            {/* perfil_id = 11 => Coordinador */}
            {(usuarioSeleccionado.perfil_id === "11")  && (
                <>
                    <ListItem button component={Link} to="/bandeja-de-entrada" >
                        <ListItemIcon><InboxIcon /></ListItemIcon>
                        <ListItemText primary="Bandeja de entrada" />
                    </ListItem>
                    <ListSubheader>ALUMNOS</ListSubheader> 
                    <ListItem button component={Link} to="/alumnos_tutor/list" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Listado de Alumnos" />
                    </ListItem>
                    <ListItem button component={Link} to="/apoyo-tutores" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Apoyo a tutores" />
                    </ListItem>
                    <ListItem button component={Link} to="/matriz_estados" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Matriz entregas" />
                    </ListItem>
                    <ListItem button component={Link} to="/matriz_simulacros" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Matriz simulacros" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutorias_individuales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías individuales" />
                    </ListItem>
                    {/* <ListItem button component={Link} to="/notificaciones_tutor_alumnos" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones alumnos" />
                    </ListItem> */}
                    <ListItem button component={Link} to="/notificaciones-alumnos" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones alumnos" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutores_tutorias_grupales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías grupales" />
                    </ListItem>
                    <ListItem button component={Link} to="/exposiciones-orales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Exposiciones orales" />
                    </ListItem>
                    <Divider />

                    <Divider />
                    <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                    <ListItem button component={Link} to="/liquidaciones" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Liquidaciones" />
                    </ListItem>
                    <ListItem button component={Link} to="/coordinacion" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Coordinación" />
                    </ListItem>
                    <ListItem button component={Link} to="/notificaciones" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones" />
                    </ListItem>
                </>
            )}

            {/* perfil_id = 13 => Jefatura de estudios */}
            {(usuarioSeleccionado.perfil_id === "13")  && (
                <>
                    
                    {/* <ListItem button component={Link} to="/home" >
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Panel de control" />
                    </ListItem> */}

                    {/* <Divider /> */}
                    <ListItem button component={Link} to="/bandeja-de-entrada" >
                        <ListItemIcon><InboxIcon /></ListItemIcon>
                        <ListItemText primary="Bandeja de entrada" />
                    </ListItem>
                    <ListSubheader>ALUMNOS (TUTORÍAS) </ListSubheader> 
                    <ListItem button component={Link} to="/alumnos_tutor/list" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Listado de Alumnos" />
                    </ListItem>
                    <ListItem button component={Link} to="/apoyo-tutores" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Apoyo a tutores" />
                    </ListItem>
                    <ListItem button component={Link} to="/matriz_estados" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Matriz entregas" />
                    </ListItem>
                    <ListItem button component={Link} to="/matriz_simulacros" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Matriz simulacros" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutorias_individuales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías individuales" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutores_tutorias_grupales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías grupales" />
                    </ListItem>
                    <ListItem button component={Link} to="/exposiciones-orales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Exposiciones orales" />
                    </ListItem>
                    {/* <ListItem button component={Link} to="/notificaciones_tutor_alumnos" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones alumnos" />
                    </ListItem> */}
                    <ListItem button component={Link} to="/notificaciones-alumnos" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones alumnos" />
                    </ListItem>
                    
                    <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                    <ListItem button component={Link} to="/coordinacion" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Coordinación" />
                    </ListItem>
                    

                    <ListItem button component={Link} to="/notificaciones" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones" />
                    </ListItem>
                    <ListItem button component={Link} to="/carga_tutores" >
                        <ListItemIcon><QueryStatsIcon /></ListItemIcon>
                        <ListItemText primary="Carga de tutores" />
                    </ListItem>
                    
                    <Divider />

                </>
            )}
            
            {/* perfil_id = 12 => Administración */}
            {(usuarioSeleccionado.perfil_id === "12")  && (
                <>
                    
                    {/* <ListItem button component={Link} to="/home" >
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Panel de control" />
                    </ListItem> */}

                    {/* <Divider /> */}
                    <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                    <ListItem button component={Link} to="/asignacion_masiva" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Asignaciones masivas" />
                    </ListItem>
                    <ListItem button component={Link} to="/plantillas_permisos" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Permisos" />
                    </ListItem>
                    <ListItem button component={Link} to="/grupos" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Grupos de alumnos" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutorias_grupales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías grupales" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutorias_grupales_enlaces" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías grupales (enlaces)" />
                    </ListItem>
                    <ListItem button component={Link} to="/exposiciones-orales" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Exposiciones orales" />
                    </ListItem>
                    <ListItem button component={Link} to="/tutorias_individuales_sin_alumno" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Tutorías individuales (sin alumno)" />
                    </ListItem>
                    {/* <ListItem button component={Link} to="/alumnos_registrados" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Alumnos registrados" />
                    </ListItem> */}
                    <Divider />
                    <ListSubheader>NOTIFICACIONES</ListSubheader>
                    {/* <ListItem button component={Link} to="/admin_difusion" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Difusiones" />
                    </ListItem> */}
                    <ListItem button component={Link} to="/notificaciones-alumnos" >
                        <ListItemIcon><NotificationsIcon /></ListItemIcon>
                        <ListItemText primary="Notificaciones alumnos" />
                    </ListItem>

                </>
            )}

            <Divider />
            <ListItem button component={Link} to="/logout" >
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary="Salir" />
            </ListItem>

            
        </div>
    );

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    let history = useHistory();
    return (
        <>
            <AppBar position="fixed" className={classes.appbar}>
                <Toolbar>
                    <Grid justify={"space-between"} container>
                        <Grid xs={1} item>
                            {showBack ?
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => history.goBack()}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                                :
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(anchor, true)}>
                                    <MenuIcon />
                                </IconButton>
                            }
                        </Grid>
                        <Grid xs={9} justifyContent='center' item>
                            <Typography variant="h6" className={classes.title}>
                                {title == null ? '.:: Tutorías Tutea ::.' : title}
                            </Typography>
                        </Grid>
                        <Grid xs={1} justifyContent='end' item>
                        </Grid>
                    </Grid>
                        <Grid item>{usuarioSeleccionado.username}</Grid>
                    {/* <Button color="inherit" component={Link} to="/logout" onClick={() => { }}>Salir</Button> */}
                </Toolbar>
            </AppBar>

            <React.Fragment key={anchor}>
                <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                >
                    <img src={avatar} className={classes.avatar} alt="avatar" />
                    {list(anchor)}
                </SwipeableDrawer>
            </React.Fragment>
        </>
    );
}

export default NavbarTop;