import DateFnsUtils from '@date-io/date-fns';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from 'date-fns/locale/es';

import { useFormik } from "formik";
import * as yup from 'yup';
import { getAlumnosEntregas, getEntregasPlantillaTutor, getEntregasPlantillasByTutor, getGrupos, getTutoriasGrupales, getZooms, insertar_alumno_entrega, insertar_entrega_grupal, insertar_tutoria_grupal, update_alumno_entrega, update_tutoria_grupal } from '../../../services/alumnos';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getListaDetalle } from '../../../services/lista_detalle';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Swal from 'sweetalert2';

export const DialogTutoriaGrupalForm = ({tutoriaGrupal, setTutorias, openModal, handleOpenModal}) => {
    const [zooms, setZooms] = useState([])
    // const [entregasPlantillaTutor, setEntregasPlantillaTutor] = useState([]);
    const [grupos, setGrupos] = useState([]);

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {

        getZooms({usuario_id: usuarioSeleccionado.id}).then( (response) => {
            setZooms(response.zooms)
        })

        getGrupos({usuario_id: usuarioSeleccionado.id}).then( response => {
            setGrupos(response.grupos)
        })
        // getEntregasPlantillaTutor({usuario_id: usuarioSeleccionado.id}).then( (response) => {
        //     setEntregasPlantillaTutor(response.entregas_plantilla_tutor)
        // })

        if(tutoriaGrupal) {

            formik.setValues({
                mail: tutoriaGrupal.mail,
                start_time: tutoriaGrupal.start_time,
                invitado: tutoriaGrupal.invitado,
                zoom_link: tutoriaGrupal.zoom_link,
                // plantilla_id: tutoriaGrupal.plantilla_id,
                grupo_id: tutoriaGrupal.grupo_id
            })

        } else {
            
            formik.setValues({
                mail: "",
                start_time: null,
                invitado: "",
                zoom_link: "",
                // plantilla_id: "",
                grupo_id: "",
            })

        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            mail: "",
            start_time: null,
            zoom_link: "",
            invitado: "",
            // plantilla_id: "",
            grupo_id: "",
        },
        validationSchema: yup.object({
            mail: yup.string().required("Sala es requerida"),
            start_time: yup.string().nullable().required("Fecha es requerida"),
            invitado: yup.string().required("Anfitrion es requeridos"),
            // plantilla_id: yup.string().required("Grupo es requerido"),
            grupo_id: yup.string().required("Grupo es requerido"),
          }),
        onSubmit: (values) => {

            handleOpenModal(false)

            if(tutoriaGrupal) {
                update_tutoria_grupal({
                    usuario_id: usuarioSeleccionado.id, 
                    tutoria_grupal_id: tutoriaGrupal.id,
                    mail: values.mail,
                    start_time: values.start_time,
                    zoom_link: values.zoom_link, 
                    invitado: values.invitado, 
                    // plantilla_id: values.plantilla_id, 
                    grupo_id: values.grupo_id, 
                }).then( (response) => {
    
                    getTutoriasGrupales({ usuario_id: usuarioSeleccionado.id }).then((response) => {
            
                        response && setTutorias(response.tutorias)

                    });
    
                })
            } else {
                insertar_tutoria_grupal({
                    usuario_id: usuarioSeleccionado.id, 
                    mail: values.mail,
                    start_time: values.start_time,
                    zoom_link: values.zoom_link, 
                    invitado: values.invitado, 
                    // plantilla_id: values.plantilla_id, 
                    grupo_id: values.grupo_id, 
                }).then( (response) => {
    
                    getTutoriasGrupales({ usuario_id: usuarioSeleccionado.id }).then((response) => {
            
                        response && setTutorias(response.tutorias)
                        
                    });
    
                })
                
            }
            
                
            //     }
            // })
            

            
        },
    });

    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <Dialog open={openModal} onClose={() => handleOpenModal(false)} maxWidth={'md'}>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

                <DialogTitle>Crear tutoría grupal</DialogTitle>
                <DialogContent style={{width: '700px'}}>


                    <Grid container>

                        <Grid item sm={12}>

                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.mail && Boolean(formik.errors.mail)}
                            >
                                <InputLabel id="mail-label">Sala</InputLabel>
                                <Select

                                    labelId="mail-label"
                                    id="mail"
                                    name="mail"
                                    label="Sala"
                                    fullWidth
                                    value={formik.values.mail}
                                    onChange={formik.handleChange}
                                    error={formik.touched.mail && Boolean(formik.errors.mail)}
                                >
                                    
                                    {        
                                        zooms && zooms.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.mail}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.mail && formik.errors.mail}</FormHelperText>
                            </FormControl>

                        </Grid>

                        <Grid item sm={12}>

                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.grupo_id && Boolean(formik.errors.grupo_id)}
                            >
                                <InputLabel id="grupo_id-label">Grupo</InputLabel>
                                <Select

                                    labelId="grupo_id-label"
                                    id="grupo_id"
                                    name="grupo_id"
                                    label="Tipo"
                                    fullWidth
                                    value={formik.values.grupo_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.grupo_id && Boolean(formik.errors.grupo_id)}
                                >
                                    
                                    {        
                                        grupos && grupos.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.descripcion}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.tipo_id && formik.errors.tipo_id}</FormHelperText>
                            </FormControl>

                        </Grid>
                        
                        {/* <Grid item sm={12}>

                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.plantilla_id && Boolean(formik.errors.plantilla_id)}
                            >
                                <InputLabel id="plantilla_id-label">Grupo</InputLabel>
                                <Select

                                    labelId="plantilla_id-label"
                                    id="plantilla_id"
                                    name="plantilla_id"
                                    label="Tipo"
                                    fullWidth
                                    value={formik.values.plantilla_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.plantilla_id && Boolean(formik.errors.plantilla_id)}
                                >
                                    
                                    {        
                                        entregasPlantillaTutor && entregasPlantillaTutor.map( (item) => {
                                            return (<MenuItem key={item.entregas_plantilla_id} value={item.entregas_plantilla_id}>{item.entregas_plantilla_tutor_nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.tipo_id && formik.errors.tipo_id}</FormHelperText>
                            </FormControl>

                        </Grid> */}

                        <Grid item sm={6} style={{marginTop: 16, paddingRight: 10}}>
                            
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    autoOk={true}
                                    id="start_time"
                                    label="Fecha Planificacion"
                                    placeholder='Introduce la fecha de planificacion'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy HH:mm"
                                    value={formik.values.start_time}
                                    onChange={value => formik.setFieldValue("start_time", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.start_time && Boolean(formik.errors.start_time)}
                                    helperText={formik.touched.start_time && formik.errors.start_time}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>

                        <Grid item sm={12}>
                            
                            <TextField
                                id="invitado"
                                name="invitado"
                                label="Anfitrion"
                                placeholder="Introduce el correo electronico del anfitrion"
                                fullWidth
                                margin="normal"
                                value={formik.values.invitado}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.invitado && Boolean(formik.errors.invitado)}
                                helperText={formik.touched.invitado && formik.errors.invitado}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>
                        <Grid item sm={12}>
                            
                            <TextField
                                id="zoom_link"
                                name="zoom_link"
                                label="Enlace de zoom"
                                placeholder="Enlace de zoom"
                                fullWidth
                                margin="normal"
                                value={formik.values.zoom_link}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.zoom_link && Boolean(formik.errors.zoom_link)}
                                helperText={formik.touched.zoom_link && formik.errors.zoom_link}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        {/* <Grid item sm={12}>
                            <AdjuntoEntrega entregaId={entrega?.id} adjunto={formik.values.adjunto} adjunto_nombre_original={formik.values.adjunto_nombre_original} setFieldValue={formik.setFieldValue}/>
                        </Grid> */}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleOpenModal(false)}>Cancelar</Button>
                    <Button type='submit'> { tutoriaGrupal ? 'Actualizar tutoría grupal' : 'Crear tutoría grupal' }</Button>
                </DialogActions>
            </form>

        </Dialog>
    )
}