import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                               Get Alumno                                */
/* -------------------------------------------------------------------------- */
export async function getAlumno({ alumno_id, usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
/* -------------------------------------------------------------------------- */
/*                               Get Alumno entregas                               */
/* -------------------------------------------------------------------------- */
export async function getAlumnosEntregas({ alumno_id, usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_entregas',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getExposicionesOralesCalendly({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/exposiciones_orales_calendly',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getExposicionesOralesAlumnos({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/exposiciones_orales_alumnos',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getLiquidaciones({ tutor_id, usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/liquidaciones_tutor',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getAlumnosTickets({ alumno_id, usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_ticket',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function ticketToConversaion({ alumno_id, usuario_id, tipo, origen_id, ticket_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("tipo", tipo);
        formData.append("origen_id", origen_id);
        formData.append("ticket_id", ticket_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/ticket_to_conversacion',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getBandejaEntrada({ usuario_id,  }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/bandeja_entrada',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}

export async function getAlumnosTutor({ usuario_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_tutor',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getAlumnosCoordinador({ usuario_id, coordinador_usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("coordinador_usuario_id", coordinador_usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_coordinador',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}

export async function getAlumnosRegistrados({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_registrados',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getEntregasPlantillaTutor({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/entregas_plantilla_tutor',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getAlumnosRegistradosReducida({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_registrados_reducida',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getPlantillasEntregasAsignables({ usuario_id, tutor_usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutor_usuario_id", tutor_usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas_asignables',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getGrupos({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/grupos_list',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function alumnoQuitarTutorPrincipal({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumno_quitar_tutor_principal',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getGruposItem({ usuario_id, grupo_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("grupo_id", grupo_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/grupos_item',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getSedesAsignables({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/get_sedes',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}

/* -------------------------------------------------------------------------- */
/*                              Insertar alumno                               */
/* -------------------------------------------------------------------------- */
export async function insertar_alumno_entrega({ usuario_id, alumno_id, abreviatura, descripcion, fecha_planificacion, fecha_prevista, cuerpo_mensaje,tipo_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("abreviatura", abreviatura);
        formData.append("descripcion", descripcion);
        formData.append("fecha_planificacion", dateToMysql(fecha_planificacion))
        formData.append("fecha_prevista", dateToMysql(fecha_prevista))
        formData.append("cuerpo_mensaje", cuerpo_mensaje);
        formData.append("tipo_id", tipo_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insert_alumnos_entregas',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_exposicion_oral_entrada_manual({ usuario_id, exposicion_oral_id, tutor_id, fecha, zoom_mail, zoom_link, zoom_password, observaciones }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("exposicion_oral_id", exposicion_oral_id);
        formData.append("tutor_id", tutor_id);
        formData.append("fecha", dateToMysql(fecha))
        formData.append("zoom_mail", zoom_mail);
        formData.append("zoom_link", zoom_link);
        formData.append("zoom_password", zoom_password);
        formData.append("observaciones", observaciones);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_exposicion_oral_entrada_manual',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_exposicion_oral_url_grabacion({ usuario_id, exposicion_oral_id, url_grabacion }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("exposicion_oral_id", exposicion_oral_id);
        formData.append("url_grabacion", url_grabacion);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_exposicion_oral_url_grabacion',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function insertar_tutoria_grupal({ usuario_id, mail, start_time, zoom_link, invitado, grupo_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("mail", mail);
        formData.append("start_time", dateToMysql(start_time))
        formData.append("zoom_link", zoom_link);
        formData.append("invitado", invitado);
        formData.append("grupo_id", grupo_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_insert',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_tutoria_grupal({ usuario_id, tutoria_grupal_id, mail, start_time, zoom_link, invitado, grupo_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_grupal_id", tutoria_grupal_id);
        formData.append("mail", mail);
        formData.append("start_time", dateToMysql(start_time))
        formData.append("zoom_link", zoom_link);
        formData.append("invitado", invitado);
        formData.append("grupo_id", grupo_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_update',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function delete_tutoria_grupal({ usuario_id, tutoria_grupal_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_grupal_id", tutoria_grupal_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_delete',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function insertar_tutoria_grupal_enlace({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        // formData.append("tutor_id", campos.tutor_id);
        formData.append("grupo_id", campos.grupo_id);
        formData.append("url_video", campos.url_video)
        formData.append("url_adjunto", campos.url_adjunto);
        formData.append("descripcion", campos.descripcion);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_enlace_insert',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_tutoria_grupal_enlace({ usuario_id, tutoria_grupal_enlace_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_grupal_enlace_id", tutoria_grupal_enlace_id);
        // formData.append("tutor_id", campos.tutor_id);
        formData.append("grupo_id", campos.grupo_id);
        formData.append("url_video", campos.url_video)
        formData.append("url_adjunto", campos.url_adjunto);
        formData.append("descripcion", campos.descripcion);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_enlace_update',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function delete_tutoria_grupal_enlace({ usuario_id, tutoria_grupal_enlace_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_grupal_enlace_id", tutoria_grupal_enlace_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_enlace_delete',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function insertar_entrega_grupal({ usuario_id, abreviatura, descripcion, fecha_planificacion, fecha_prevista, cuerpo_mensaje,tipo_id, plantillas_seleccionadas}) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("plantillas_seleccionadas", plantillas_seleccionadas);
        formData.append("abreviatura", abreviatura);
        formData.append("descripcion", descripcion);
        formData.append("fecha_planificacion", dateToMysql(fecha_planificacion))
        formData.append("fecha_prevista", dateToMysql(fecha_prevista))
        formData.append("cuerpo_mensaje", cuerpo_mensaje);
        formData.append("tipo_id", tipo_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insertar_entrega_grupal',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function insertar_grupo_entrega({ usuario_id, abreviatura, descripcion, fecha_planificacion, fecha_prevista, cuerpo_mensaje,tipo_id, grupos_seleccionados}) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("grupos_seleccionados", grupos_seleccionados);
        formData.append("abreviatura", abreviatura);
        formData.append("descripcion", descripcion);
        formData.append("fecha_planificacion", dateToMysql(fecha_planificacion))
        formData.append("fecha_prevista", dateToMysql(fecha_prevista))
        formData.append("cuerpo_mensaje", cuerpo_mensaje);
        formData.append("tipo_id", tipo_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insertar_grupo_entrega',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_alumno_entrega({ usuario_id, alumno_id, entrega_id, abreviatura, descripcion, fecha_planificacion, fecha_prevista, cuerpo_mensaje, tipo_id}) {
    try {
        let formData = new FormData();

        formData.append("entrega_id", entrega_id);
        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("abreviatura", abreviatura);
        formData.append("descripcion", descripcion);
        formData.append("fecha_planificacion", dateToMysql(fecha_planificacion))
        formData.append("fecha_prevista", dateToMysql(fecha_prevista))
        formData.append("cuerpo_mensaje", cuerpo_mensaje);
        formData.append("tipo_id", tipo_id);
        // formData.append("adjunto", adjunto);
        // formData.append("adjunto_nombre_original", adjunto_nombre_original);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_alumnos_entregas',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_alumno_entrega_cambiar_fechas({ usuario_id, alumno_id, entrega_id, fecha_planificacion, fecha_prevista}) {
    try {
        let formData = new FormData();

        formData.append("entrega_id", entrega_id);
        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("fecha_planificacion", dateToMysql(fecha_planificacion))
        formData.append("fecha_prevista", dateToMysql(fecha_prevista))

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_alumnos_entregas_cambiar_fechas',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function delete_alumno_entrega({ usuario_id, entrega_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("entrega_id", entrega_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/delete_alumnos_entregas',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function importar_alumno_entregas_plantilla({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/importar_alumno_entregas_plantilla',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                           Enviar duda liquidacion                          */
/* -------------------------------------------------------------------------- */
export async function enviar_duda_liquidacion({ usuario_id, descripcion, liquidacion_id, liquidacion_uuid }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("descripcion", descripcion);
        formData.append("liquidacion_id", liquidacion_id);
        formData.append("liquidacion_uuid", liquidacion_uuid);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/enviar_liquidacion_duda',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
/* -------------------------------------------------------------------------- */
/*                                forzar envio                                */
/* -------------------------------------------------------------------------- */
export async function forzar_envio({ usuario_id, entrega_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("entrega_id", entrega_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_forzar_envio',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }
}

/* -------------------------------------------------------------------------- */
/*                             GetAlumnosSinTutor                             */
/* -------------------------------------------------------------------------- */
export async function getAlumnosSinTutor({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_sin_tutor',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutoriasIndividuales({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutorias_individuales',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getAlumnoTutoriasIndividuales({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutorias_individuales',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutoriasGrupales({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutorias_grupales',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutoriasGrupalesEnlaces({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutorias_grupales_enlaces',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutorTutoriasGrupales({ usuario_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutor_tutorias_grupales',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function tutoriaGrupalInformarSolicitante({ usuario_id, tutoria_grupal_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_grupal_id", tutoria_grupal_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_informar_solicitante',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function tutoriaGrupalInformarAlumnos({ usuario_id, tutoria_grupal_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_grupal_id", tutoria_grupal_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutoria_grupal_informar_alumnos',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
// export async function getAlumnosSinTutor({ usuario_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);

//         const response = await axios({
//             method: 'post',
//             url: BASE_URL_SERVICE + 'alumno/alumnos_sin_tutor',
//             data: formData,
//             headers: {
//             },
//          });

//         return response.data;
//     } catch (error) {
//         return undefined;
//     }
// }
export async function getDifusionDestinosAdministracion({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/difusion_destinos_administracion',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutoriasIndividualesSinAlumno({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutorias_individuales_sin_alumno',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function actualizarCalendly({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/update_calendly',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function actualizarTutoriaEstadoTutor({ usuario_id, tutoria_id, estado }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_id", tutoria_id);
        formData.append("estado", estado);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/update_tutoria_estado_tutor',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function actualizarTutoriaEsBienvenida({ usuario_id, tutoria_id, es_bienvenida }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_id", tutoria_id);
        formData.append("es_bienvenida", es_bienvenida);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/update_tutoria_es_bienvenida',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function actualizarTutoriaInvitado({ usuario_id, tutoria_id, invitado }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutoria_id", tutoria_id);
        formData.append("invitado", invitado);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/update_tutoria_invitado',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function actualizarCalendlyTutoriasGrupales({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/update_calendly_tutorias_grupales',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function enviarMensajeDirectoTutorAlumno({ usuario_id, asunto, descripcion, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("asunto", asunto);
        formData.append("descripcion", descripcion);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/enviar_mensaje_directo_tutor_alumno',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function enviarMensajeDirectoAdminGrupos({ usuario_id, asunto, descripcion, grupos_seleccionados }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("asunto", asunto);
        formData.append("descripcion", descripcion);
        formData.append("grupos_seleccionados", grupos_seleccionados);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/enviar_mensaje_directo_admin_grupos',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutorAlumnoMensajesDirectos({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutor_alumno_mensajes_directos',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutorNotificaciones({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutor_notificaciones',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getTutorNotificacionesDestinos({ usuario_id, notificacion_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("notificacion_id", notificacion_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutor_notificaciones_destinos',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
/* -------------------------------------------------------------------------- */
/*                             GetAlumnosSinTutor                             */
/* -------------------------------------------------------------------------- */
export async function notificacionesUsuarioPendientes({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/notificaciones_usuario_pendientes',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function marcarNotificacionLeida({ usuario_id, id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("id", id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/notificaciones_marcar_leida',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function notificacionesUsuario({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/notificaciones_usuario',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function notificacionHilo({ usuario_id, notificacion_usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("notificacion_usuario_id", notificacion_usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/notificacion_usuario_hilo',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function notificacionTutoresDestino({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/notificacion_tutores_destino',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function notificacionTutoresDestinoCreate({ usuario_id, descripcion, destinatarios }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("descripcion", descripcion);
        formData.append("destinatarios", destinatarios);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/notificacion_tutores_destino_create',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function notificacionTutoresDestinoRespuesta({ origen_usuario_id, destino_usuario_id, descripcion, notificacion_usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("notificacion_usuario_id", notificacion_usuario_id);
        formData.append("origen_usuario_id", origen_usuario_id);
        formData.append("destino_usuario_id", destino_usuario_id);
        formData.append("descripcion", descripcion);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/notificacion_tutores_destino_respuesta',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function enviarDifusion({ usuario_id, plantilla_id, asunto, descripcion }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("plantilla_id", plantilla_id);
        formData.append("asunto", asunto);
        formData.append("descripcion", descripcion);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/enviar_difusion',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function enviarCorreoMasivo({ usuario_id, asunto, descripcion, adjunto, alumnosSeleccionados }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("asunto", asunto);
        formData.append("descripcion", descripcion);
        formData.append("adjunto", adjunto);
        formData.append("adjunto_nombre_original", adjunto.name);
        formData.append("alumnos_seleccionados", alumnosSeleccionados);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/enviar_correo_masivo',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}

/* -------------------------------------------------------------------------- */
/*                             alumno tutor notar                             */
/* -------------------------------------------------------------------------- */
export async function getAlumnoTutorNotas({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumno_tutor_notas',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function alumnoTutorNotaInsert({ usuario_id, nota, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("nota", nota);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumno_tutor_notas_create',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
/* -------------------------------------------------------------------------- */
/*                            GetEntregasPlantillas                           */
/* -------------------------------------------------------------------------- */
export async function getEntregasPlantillasByTutor({ tutor_id, plantilla_id = '-1' }) {
    try {
        let formData = new FormData();

        formData.append("tutor_id", tutor_id);
        formData.append("plantilla_id", plantilla_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getZooms({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/get_zooms',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
export async function getMatrizEstados({ usuario_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/matriz_estados',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}

export async function entregaPlantillaCreate({ usuario_id, tutor_id, descripcion }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutor_id", tutor_id);
        formData.append("descripcion", descripcion);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas_create',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function grupoCreate({ usuario_id, descripcion }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("descripcion", descripcion);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_grupo_create',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function entregaPlantillaUpdate({ usuario_id, plantilla_id, tutor_id, descripcion }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("id", plantilla_id);
        formData.append("tutor_id", tutor_id);
        formData.append("descripcion", descripcion);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas_update',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function grupoUpdate({ usuario_id, grupo_id, descripcion }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("grupo_id", grupo_id);
        formData.append("descripcion", descripcion);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/grupo_update',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function entregaPlantillaLineaCreate({ usuario_id, plantilla_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_entregas_plantilla_id", plantilla_id);
        formData.append("abreviatura", campos.abreviatura);
        formData.append("descripcion", campos.descripcion);
        formData.append("fecha_planificacion", dateToMysql(campos.fecha_planificacion));
        formData.append("fecha_prevista", dateToMysql(campos.fecha_prevista));
        formData.append("cuerpo_mensaje", campos.cuerpo_mensaje);
        formData.append("tipo_id", campos.tipo_id);
        // formData.append("adjunto", campos.adjunto);
        // formData.append("adjunto_nombre_original", campos.adjunto.name);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas_linea_create',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function grupoEntregaCreate({ usuario_id, grupo_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("grupo_id", grupo_id);
        formData.append("abreviatura", campos.abreviatura);
        formData.append("descripcion", campos.descripcion);
        formData.append("fecha_planificacion", dateToMysql(campos.fecha_planificacion));
        formData.append("fecha_prevista", dateToMysql(campos.fecha_prevista));
        formData.append("cuerpo_mensaje", campos.cuerpo_mensaje);
        formData.append("tipo_id", campos.tipo_id);
        // formData.append("adjunto", campos.adjunto);
        // formData.append("adjunto_nombre_original", campos.adjunto.name);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/grupo_entrega_create',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function grupoEntregaDelete({ usuario_id, grupo_entrega_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("grupo_entrega_id", grupo_entrega_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/grupo_entrega_delete',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function grupoTutoresInsert({ usuario_id, grupo_id, tutor_id, funcion_tutor }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("grupo_id", grupo_id);
        formData.append("tutor_id", tutor_id);
        formData.append("funcion_tutor", funcion_tutor);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/grupo_tutor_insert',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function grupoTutoresDelete({ usuario_id, grupo_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("grupo_id", grupo_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/grupo_tutor_delete',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function entregaPlantillaLineaAdjuntoDelete({ usuario_id, plantilla_linea_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("plantilla_linea_id", plantilla_linea_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas_linea_adjunto_delete',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function entregaAdjuntoDelete({ usuario_id, entrega_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("entrega_id", entrega_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_adjunto_delete',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function entregaPlantillaLineaUpdate({ usuario_id, plantilla_id, entrega_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("id", entrega_id);
        formData.append("alumno_entregas_plantilla_id", plantilla_id);
        formData.append("abreviatura", campos.abreviatura);
        formData.append("descripcion", campos.descripcion);
        formData.append("fecha_planificacion", dateToMysql(campos.fecha_planificacion));
        formData.append("fecha_prevista", dateToMysql(campos.fecha_prevista));
        formData.append("cuerpo_mensaje", campos.cuerpo_mensaje);
        formData.append("tipo_id", campos.tipo_id);
        // formData.append("adjunto", campos.adjunto);
        // formData.append("adjunto_nombre_original", campos.adjunto.name);

        console.log(campos)

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas_linea_update',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function entregaPlantillaLineaDelete({ usuario_id, entrega_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("id", entrega_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumnos_entregas_plantillas_linea_delete',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}

/* -------------------------------------------------------------------------- */
/*                                Update tutor                                */
/* -------------------------------------------------------------------------- */
export async function update_tutor({ usuario_id, alumno_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/actualizar_tutor',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function update_tutor_exposicion({ usuario_id, alumno_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/actualizar_tutor_exposicion',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}

/* -------------------------------------------------------------------------- */
/*                           Update plantilla alumno                          */
/* -------------------------------------------------------------------------- */
export async function update_grupo_alumno({ usuario_id, alumno_id, grupo_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("grupo_id", grupo_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/actualizar_grupo_alumno',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
// export async function update_plantilla_alumno({ usuario_id, alumno_id, plantilla_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);
//         formData.append("alumno_id", alumno_id);
//         formData.append("plantilla_id", plantilla_id);

//         const response = await axios({
//            method: 'post',
//            url: BASE_URL_SERVICE + 'alumno/actualizar_plantilla_entregas_alumno',
//            data: formData,
//            headers: {
//            },
//         });

//         return response.data;
//     } catch (error) {
//         return undefined;
//     }

// }
export async function update_sede_alumno({ usuario_id, alumno_id, sede_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("sede_id", sede_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/actualizar_sede_alumno',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function conversacion_add_tutor_apoyo({ usuario_id, origen, origen_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("origen", origen);
        formData.append("origen_id", origen_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/conversacion_add_tutor_apoyo',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function getApoyoTutores({ usuario_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/apoyo_tutores_by_tutor',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function conversacion_delete_tutor_apoyo({ usuario_id, origen, origen_id, tutor_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("origen", origen);
        formData.append("origen_id", origen_id);
        formData.append("tutor_id", tutor_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/conversacion_delete_tutor_apoyo',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
export async function conversacion_tutor_apoyo({ usuario_id, origen, origen_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("origen", origen);
        formData.append("origen_id", origen_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/conversacion_tutor_apoyo',
           data: formData,
           headers: {
           },
        });

        return response.data;
    } catch (error) {
        return undefined;
    }

}
